
  import { Component, Vue, Emit, Prop, Watch, Mixins } from "vue-property-decorator";

  @Component({
    components: {},
  })
  export default class Check extends Vue {
    @Prop() img_url: string;
    @Prop({ default: true }) isvalid: boolean;
    @Prop() type: string;

    @Emit()
    clicked() {
      return true;
    }
  }
